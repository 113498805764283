import React from "react"
import SectionPageMarginGradient from '../Section/SectionPageMarginGradient'
import Grid1 from '../Grid1/Grid1'
import PageTitle from '../../Type/PageTitle'
import Paragraph1 from '../../Type/Paragraph1'
import Spacer from '../Spacer/Spacer'

const PageHeader = ({ bgColor, title, text, textColor, marginBottom, breadcrumb }) => {
    if(!bgColor) bgColor = "#3479ae";
    if(!textColor) textColor = "white";
    
  return (
    <>
       <SectionPageMarginGradient marginBottom={marginBottom} >
            <Grid1>
                <PageTitle color={textColor}>{title}</PageTitle>
                <Paragraph1 color={textColor}>
                    {text}
                </Paragraph1>
                {breadcrumb}
                <Spacer height="40px" />
            </Grid1>
        </SectionPageMarginGradient>
    </>
  )
} 

export default PageHeader




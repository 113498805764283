import React from 'react';
import { Link } from "gatsby"
import SectionPageMargin from '../Layout/Section/SectionPageMargin'
import Grid2 from '../Layout/Grid2/Grid2'
import GridItem from '../Layout/GridItem/GridItem'
import styled from 'styled-components'
import FacebookIcon from '../../images/assets/facebook.svg'
import TwitterIcon from '../../images/assets/twitter.svg'
import InstagramIcon from '../../images/assets/instagram.svg'
import IconButton from '../Layout/Button/IconButton'


const footer = props => (

         <SectionPageMargin bgColor="#3479ae" marginTop="0px" marginBottom="0px">
       
                <Grid2 style={{paddingTop: '50px', paddingBottom: '50px'}}>
                    <GridItem order="2">
                        <StyledLink to="/terms/"><span style={{whiteSpace: "nowrap"}}>Terms and conditions</span></StyledLink>
                        <StyledLink to="/privacy/"><span style={{whiteSpace: "nowrap"}}>Privacy policy</span></StyledLink>
                        <StyledLink to="/contact/">Contact</StyledLink>
                        <StyledLink to="/covid-safe-measures/"><span style={{whiteSpace: "nowrap"}}>Covid Measures</span></StyledLink>	
                        <CopyContainer>
                            {'Copyright © Summon IO Pty Ltd'}
                                {' '}
                                {new Date().getFullYear()}
                                {'.'} <br/>
                            <ACNContainer>ACN: 640 473 463</ACNContainer>
                        </CopyContainer>
                    </GridItem>
                    <GridItem order="1" align="right">
                        <IconContainer>
                            <IconButton onClick={(event) => handleClick("https://www.facebook.com/summoncars")}>
                                <FacebookIcon/> 
                            </IconButton>
                            <IconButton onClick={(event) => handleClick("https://www.twitter.com/summon_cars")}>
                                <TwitterIcon/> 
                            </IconButton>
                            <IconButton onClick={(event) => handleClick("https://www.instagram.com/summoncars")}>
                                <InstagramIcon/> 
                            </IconButton>
                        </IconContainer>
                    </GridItem>
                </Grid2>
    
         </SectionPageMargin>
   
);

const handleClick = (url) => {
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    a.rel = "noopener";
    a.click();
}


const CopyContainer = styled.div`
    padding-top: 10px;
    padding-bottom: 20px;
    color: white;
`;

const ACNContainer = styled.div`
    padding-top: 5px;
    color: white;
`;


const StyledLink = styled(Link)`

 font-weight: bold;
 text-decoration: none;
 color: white;
 margin-right: 30px;
 line-height: 2rem;

 &:hover {
    color: #c7edfb;
  }


 @media (max-width: 800px) {
    display: block;
    margin-bottom: 10px; 
  }

`

const IconContainer = styled.div`

    padding-bottom: 20px;
`;



export default footer;
import React from 'react';
import { Link } from "gatsby"
import Section from "../Layout/Section/Section"
import '../SideDrawer/DrawerToogleButton';
import Logo from "./assets/summon-logo-white.svg";

import './MainNavigation.css';
import DrawerToggleButton from '../SideDrawer/DrawerToogleButton';

const mainNavigation = props => {
    
    const activeStyles ={
        borderBottom: '2px solid white'
    }


    return (
    
    <Section bgColor={props.hideBG? "" : "#3988c4"} marginBottom="0px">
        <div className="main-navigation">
        
            <div className="toolbar__toggle-button"><DrawerToggleButton click={props.drawClickHandler}/></div>
            <div className="main-navigation__logo">
                <Link title="Summon Home" to="/"><Logo width="170px" alt="Summon"/></Link>            
            </div>
            <div className="spacer"></div>
        
            <nav className="main-navigation__items">
                <ul>
                    
                    <li><Link to="https://value.summon.com.au//"  activeStyle={activeStyles} partiallyActive={true}>Free valuation</Link></li>
                    <li><Link to="/articles/" activeStyle={activeStyles} partiallyActive={true}>Articles</Link></li>
                    <li><Link to="/sell-with-summon/" activeStyle={activeStyles} partiallyActive={true}>Sell your car</Link></li>
                    <li><Link to="/recent-listings/"  activeStyle={activeStyles} partiallyActive={true}>Listings</Link></li>
                    <li><Link to="/reviews/" activeStyle={activeStyles} partiallyActive={true}>Reviews</Link></li>
                    <li><Link to="/faq/" activeStyle={activeStyles} partiallyActive={true}>FAQ</Link></li> 
                    <li><Link to="/about/" activeStyle={activeStyles} partiallyActive={true}>About</Link></li>
                    {/* <li><Link to="/contact" activeStyle={activeStyles} partiallyActive={true}>Contact</Link></li> */}
                </ul>
            </nav>
        </div>
        
    </Section>

    );
}

export default mainNavigation;
import React from "react"
import styled from 'styled-components'

const Paragraph1 = ({ color, children }) => {
   if(!color) {
       color = "black";  
   }
    return (
        <Paragraph color={color}>{children}</Paragraph>
  )
} 

export default Paragraph1;

const Paragraph = styled.p`
    color: ${props => props.color};
    font-size: 1.1rem;
    line-height: 1.7rem;
    letter-spacing: -0.05rem;
    margin-top: 10px;
`

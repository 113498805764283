import React from "react"
import styled from 'styled-components'

const Grid1 = ({children }) => {
  return (
    <>
        <Grid>
            {children}
        </Grid>
    </>
  )
} 

export default Grid1

const Grid = styled.div`
    display: block;
    max-width: 800px;
`;

import React from "react"
import styled from 'styled-components'

const PageTitle = ({ color, children }) => {
   if(!color) {
       color = "black";  
   }
    return (
        <Title color={color}>{children}</Title>
  )
} 

export default PageTitle;

const Title = styled.h1`
    margin-top: 30px;
    font-weight: 600;
    color: ${props => props.color};
    font-size: 3rem;
    line-height: 3rem;
    letter-spacing: -0.1rem;
`

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Footer from "../Footer/Footer"
import Header from "../Header/Header"
import "./Layout.css"

const Layout = ({ children }) => {
  
  return (
    <>
        <Header />
      
          <main>{children}</main>

        <Footer />
      
    </>
  )
} 

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

import React from "react"
import styled from 'styled-components'

const SectionPageMarginGradient = ({ bgColor, children, marginTop, marginBottom }) => {
  if(!marginTop) marginTop = "0px";
  if(!marginBottom) marginBottom = "100px";

  return (
    <>
        <Wrapper bgColor={bgColor} marginTop={marginTop} marginBottom={marginBottom}>
            <Content>{children}</Content>
        </Wrapper>
    </>
  )
} 

export default SectionPageMarginGradient

const Wrapper = styled.section`
    display: grid;
    margin-top:  ${props => props.marginTop};
    margin-bottom:  ${props => props.marginBottom};
    grid-template-columns: minmax(400px, 1300px);
    background: rgb(57,136,196);
    background: linear-gradient(180deg, rgba(57,136,196,1) 0%, rgba(49,117,169,1) 100%);
    justify-content: center;
`;

const Content = styled.div`
    margin-left: 100px;
    margin-right: 100px;

    @media (max-width: 800px) {
      justify-content: center;
      margin-left: 30px;
      margin-right: 30px;
    }
`;

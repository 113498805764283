import React, { Component } from "react";

import MainNavigation from "../Navigation/MainNavigation";
import SideDrawer from "../SideDrawer/SideDrawer";
import Backdrop from "../Backdrop/Backdrop";

class Header extends Component {

    state = {sideDrawerOpen: false };

    drawerToggleClickHandler = () => {
		this.setState(prevState => {
			return { sideDrawerOpen: !prevState.sideDrawerOpen };
		});
	};

	backdropClickHandler = () => {
		this.setState({ sideDrawerOpen: false });
    };
    

    render() {

        let backDrop;
		if (this.state.sideDrawerOpen) {
			backDrop = <Backdrop click={this.backdropClickHandler} />;
        }
        
        return (
            <header>
                <MainNavigation
				drawClickHandler={this.drawerToggleClickHandler}
                hideBG={this.props.hideBG}
                />
                <SideDrawer
                    show={this.state.sideDrawerOpen}
                    click={this.backdropClickHandler}
                />
                {backDrop}
            </header>                 
        );

    }

}

export default Header;

import React from 'react'
import { Link } from "gatsby"


import './SideDrawer.css';

const sideDrawer = props => {

            
    let drawerClasses = 'side-drawer';
    if (props.show) {
        drawerClasses = 'side-drawer open'; 
    }

    return (
        
        <nav className={drawerClasses}>
            <ul>
                <li><Link onClick={props.click} to="/">Home</Link></li>
                <li><Link onClick={props.click} to="https://value.summon.com.au//">Free valuation</Link></li>
                <li><Link onClick={props.click} to="/articles/">Articles</Link></li>
                <li><Link onClick={props.click} to="/sell-with-summon/">Sell your car</Link></li>
                <li><Link onClick={props.click} to="/recent-listings/">Listings</Link></li>
                <li><Link onClick={props.click} to="/reviews/">Reviews</Link></li>
                <li><Link onClick={props.click} to="/faq/">FAQ</Link></li> 
                <li><Link onClick={props.click} to="/about/">About</Link></li>
                <li><Link onClick={props.click} to="/contact/">Contact</Link></li> 
            </ul>
        </nav>

    );
       
};

export default sideDrawer
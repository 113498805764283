import React from "react"
import styled from 'styled-components'

const Spacer = ({ height }) => {
  return (
        <Space height={height}></Space>
  )
} 
export default Spacer

const Space = styled.div`
    display: block;
    width: 100%;
    height: ${props => props.height};
`;
